import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';

const segments = [
  { color: 'from-emerald-400 to-emerald-600', value: 1000 },
  { color: 'from-amber-400 to-amber-600', value: 500 },
  { color: 'from-rose-400 to-rose-600', value: 100 },
  { color: 'from-violet-400 to-violet-600', value: 200 },
  { color: 'from-cyan-400 to-cyan-600', value: 50 },
  { color: 'from-fuchsia-400 to-fuchsia-600', value: 2000 },
  { color: 'from-lime-400 to-lime-600', value: 150 },
  { color: 'from-orange-400 to-orange-600', value: 300 },
];

const UltimateSpinner = () => {

  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [result, setResult] = useState<string | null>(null);
  const [totalWinnings, setTotalWinnings] = useState(0);
  const [spinsLeft, setSpinsLeft] = useState(3);

  const spinWheel = useCallback(() => {
    if (isSpinning || spinsLeft <= 0) return;

    setIsSpinning(true);
    setResult(null);
    const newRotation = rotation + 1800 + Math.random() * 360; // At least 5 full spins + random
    setRotation(newRotation);

    setTimeout(() => {
      setIsSpinning(false);
      const winningIndex = Math.floor(((newRotation % 360) / 360) * segments.length);
      const winAmount = segments[winningIndex].value;
      setResult(`You won $${winAmount}!`);
      setTotalWinnings(prev => prev + winAmount);
      setSpinsLeft(prev => prev - 1);
      
      // Trigger confetti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    }, 5000);
  }, [isSpinning, rotation, spinsLeft]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault();
        spinWheel();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [spinWheel]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 p-4">
      <h1 className="text-4xl font-bold text-white mb-8">Ultimate Prize Wheel</h1>
      <div className="relative w-72 h-72 md:w-96 md:h-96">
        <motion.div 
          className="w-full h-full rounded-full border-8 border-white shadow-2xl relative overflow-hidden"
          style={{ originX: 0.5, originY: 0.5 }}
          animate={{ rotate: rotation }}
          transition={{ duration: 5, ease: "circOut" }}
        >
          {segments.map((segment, index) => (
            <div
              key={index}
              className={`absolute w-full h-full bg-gradient-to-r ${segment.color}`}
              style={{
                clipPath: `polygon(50% 50%, ${50 - 50 * Math.cos((index * 360) / segments.length * Math.PI / 180)}% ${50 - 50 * Math.sin((index * 360) / segments.length * Math.PI / 180)}%, ${50 - 50 * Math.cos(((index + 1) * 360) / segments.length * Math.PI / 180)}% ${50 - 50 * Math.sin(((index + 1) * 360) / segments.length * Math.PI / 180)}%)`,
              }}
            >
              <span
                className="absolute text-white text-xl font-bold transform -translate-x-1/2 -translate-y-1/2"
                style={{
                  left: '75%',
                  top: '50%',
                  transform: `rotate(${(index + 0.5) * (360 / segments.length)}deg) translate(-50%, -50%)`,
                }}
              >
                ${segment.value}
              </span>
            </div>
          ))}
        </motion.div>
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-4 w-0 h-0 border-l-[20px] border-r-[20px] border-b-[40px] border-b-white z-10" />
      </div>
      <motion.button
        onClick={spinWheel}
        disabled={isSpinning || spinsLeft <= 0}
        className="mt-8 px-8 py-4 bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-xl font-bold rounded-full hover:from-indigo-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {isSpinning ? 'Spinning...' : spinsLeft > 0 ? `Spin (${spinsLeft} left)` : 'No spins left'}
      </motion.button>
      <AnimatePresence>
        {result && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="mt-6 text-3xl text-white font-bold"
          >
            {result}
          </motion.div>
        )}
      </AnimatePresence>
      <div className="mt-6 text-2xl text-white">
        Total Winnings: <span className="font-bold text-green-400">${totalWinnings}</span>
      </div>
      {spinsLeft <= 0 && (
        <motion.button
          onClick={() => {
            setSpinsLeft(3);
            setTotalWinnings(0);
            setResult(null);
          }}
          className="mt-6 px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Reset Game
        </motion.button>
      )}
    </div>
  );
};

export default UltimateSpinner;