// App.tsx
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider as PolarisProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import { storefrontClient, adminClient } from './apolloClient';
import Home from './components/Home';
import Spinner from './components/Spinner';
import HostSignUp from './components/HostSignUp';
import GetStarted from './components/GetStarted';

function App() {
  return (
    <PolarisProvider i18n={enTranslations}>
      <ApolloProvider client={storefrontClient}>
        <ApolloProvider client={adminClient}>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/spinner" element={<Spinner />} />
              <Route path="/host-signup" element={<HostSignUp />} />
              <Route path="/getstarted" element={<GetStarted />} />
            </Routes>
          </Router>
        </ApolloProvider>
      </ApolloProvider>
    </PolarisProvider>
  );
}

export default App;