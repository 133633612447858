import React from 'react';
import Header from '../components/common/Header';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import HowItWorksSection from '../components/home/HowItWorksSection';
import Footer from '../components/common/Footer';
import TypeForm from '../components/home/TypeForm';


const GetStarted: React.FC = () => {
  return (
    <div className="bg-black">
    
     
        <TypeForm />
       
    </div>
  );
}

export default GetStarted;