import React from 'react';

const TypeForm = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="https://walknsell.typeform.com/to/dyVAm2Fe"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        title="Typeform"
      ></iframe>
    </div>
  );
};

export default TypeForm;
