// apolloClient.ts
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Storefront API Client
const storefrontHttpLink = createHttpLink({
  uri: `https://${process.env.REACT_APP_SHOPIFY_STORE_DOMAIN}/api/${process.env.REACT_APP_SHOPIFY_API_VERSION}/graphql.json`,
});

const storefrontMiddleware = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN || '',
    }
  }
});

export const storefrontClient = new ApolloClient({
  link: storefrontMiddleware.concat(storefrontHttpLink),
  cache: new InMemoryCache(),
});

// Admin API Client
const adminHttpLink = createHttpLink({
  uri: `https://${process.env.REACT_APP_SHOPIFY_STORE_DOMAIN}/admin/api/${process.env.REACT_APP_SHOPIFY_API_VERSION}/graphql.json`,
});

const adminMiddleware = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Shopify-Access-Token': process.env.REACT_APP_SHOPIFY_ADMIN_ACCESS_TOKEN || '',
    }
  }
});

export const adminClient = new ApolloClient({
  link: adminMiddleware.concat(adminHttpLink),
  cache: new InMemoryCache(),
});