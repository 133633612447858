// HostSignUp.tsx
import React, { useState, useCallback } from 'react';
import {
  Page,
  Form,
  FormLayout,
  TextField,
  Button,
  Card,
  Banner,
  Layout,
} from '@shopify/polaris';
import { useMutation, gql } from '@apollo/client';
import { storefrontClient, adminClient } from '../apolloClient';

// Storefront API mutation for customer creation
const CREATE_CUSTOMER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
        email
        firstName
        lastName
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// Admin API mutation for adding a tag to a customer
const ADD_CUSTOMER_TAG = gql`
  mutation customerUpdate($input: CustomerInput!) {
    customerUpdate(input: $input) {
      customer {
        id
        tags
      }
      userErrors {
        field
        message
      }
    }
  }
`;

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }
  
  const HostSignUp: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    });
    const [error, setError] = useState<string | null>(null);
  
    const [createCustomer, { loading: createLoading }] = useMutation(CREATE_CUSTOMER, { client: storefrontClient });
    const [addCustomerTag, { loading: tagLoading }] = useMutation(ADD_CUSTOMER_TAG, { client: adminClient });
  
    const handleSubmit = useCallback(async () => {
      setError(null);
      try {
        // Create customer using Storefront API
        const { data: customerData } = await createCustomer({
          variables: {
            input: formData,
          },
        });
  
        if (customerData.customerCreate.customerUserErrors.length > 0) {
          setError(customerData.customerCreate.customerUserErrors[0].message);
          return;
        }
  
        // Add 'host' tag to customer using Admin API
        const { data: tagData } = await addCustomerTag({
          variables: {
            input: {
              id: customerData.customerCreate.customer.id,
              tags: ['host'],
            },
          },
        });
  
        if (tagData.customerUpdate.userErrors.length > 0) {
          console.error('Failed to add host tag:', tagData.customerUpdate.userErrors);
          setError('Account created, but failed to set as host. Please contact support.');
          return;
        }
  
        // Handle successful signup
        console.log('Host created successfully:', customerData.customerCreate.customer);
        // TODO: Implement success handling (e.g., redirect to dashboard, show success message)
      } catch (err) {
        setError('An unexpected error occurred. Please try again.');
      }
    }, [createCustomer, addCustomerTag, formData]);
  
    const handleChange = useCallback((value: string, id: string) => {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }, []);
  
    return (
      <Page title="Sign Up as Host">
        <Layout>
          <Layout.Section>
            <Card>
              <Form onSubmit={handleSubmit}>
                <FormLayout>
                  {error && (
                    <Banner tone="critical">
                      <p>{error}</p>
                    </Banner>
                  )}
                  <TextField
                    label="First Name"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    autoComplete="given-name"
                  />
                  <TextField
                    label="Last Name"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    autoComplete="family-name"
                  />
                  <TextField
                    type="email"
                    label="Email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="email"
                  />
                  <TextField
                    type="password"
                    label="Password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="new-password"
                  />
                  <Button submit loading={createLoading || tagLoading}>
                    Sign Up as Host
                  </Button>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  };
  
  export default HostSignUp;